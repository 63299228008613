<template>
    <div class="q-pa-md">
    <q-table
      title="Users"
      :rows="users"
      :columns="columns"
      :filter="filter"
      row-key="name"
    >
      <template v-slot:top>
        <q-space />
        <q-input outlined dense debounce="300" color="primary" v-model="filter">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td class="text-primary" key="id" :props="props" @click="showDialog(props.row.id)">
              {{ props.row.id }}
          </q-td>
          <q-td key="email" :props="props">
              {{ props.row.email }}
          </q-td>
          <q-td key="positionTitle" :props="props">
              {{ props.row.positionTitle }}
          </q-td>
          <q-td key="officeLocation" :props="props">
              {{ props.row.officeLocation }}
          </q-td>
        </q-tr>
      </template>
    </q-table>
    <q-dialog
      v-model="userDialog"
    >
      <q-card style="width: 800px; max-width: 80vw;">
        <div class="q-pa-md">
          <q-form
            class="q-pa-md"
          >
            <div class="row q-col-gutter-x-md q-col-gutter-y-md">
              <div class="col-6">
                <q-input
                  v-model="user.firstName"
                  :dense="true"
                  label="First Name"
                  outlined
                />
              </div>
              <div class="col-6">
                <q-input
                  v-model="user.lastName"
                  :dense="true"
                  label="Last Name"
                  outlined
                />
              </div>
              <div class="col-4">
                <q-input
                  v-model="user.email"
                  :dense="true"
                  label="Email"
                  outlined
                />
              </div>
              <div class="col-5">
                <q-input
                  v-model="user.positionTitle"
                  :dense="true"
                  label="Position Title"
                  outlined
                />
              </div>
              <div class="col-3">
                <q-input
                  v-model="user.officeLocation"
                  :dense="true"
                  label="Office Location"
                  outlined
                />
              </div>
              <div class="col-5">
                <q-btn color="white" text-color="black" label="Update Info" />
              </div>
            </div>
            <q-separator spaced />
              <div class="row q-col-gutter-x-md q-col-gutter-y-md">
                <div class="col-5">
                  <q-input
                    v-model="register.password"
                    :dense="true"
                    label="Password"
                    outlined
                  />
                </div>
                <div class="col-7"></div>
                <div class="col-5">
                  <q-input
                    v-model="register.confirmPassword"
                    :dense="true"
                    label="Confirm Password"
                    outlined
                  />
                </div>
                <div class="col-7"></div>
                <div class="col-5">
                  <q-btn color="white" text-color="black" label="Reset Password" @click="resetPassowrd()"/>
                </div>
              </div>
            <q-separator spaced />
              <div class="row q-col-gutter-x-md q-col-gutter-y-md">
                <div class="col-10">
                  <q-select
                    label="Roles"
                    outlined
                    option-value="id"
                    option-label="name"
                    :emit-value="true"
                    :dense="true"
                    v-model="role"
                    :options="roles" />
                </div>
                <div class="col-2">
                  <q-btn color="green" text-color="white" label="Add" @click="addUserRole()"/>
                </div>
                <div class="col-12">
                  <q-list bordered separator>
                    <q-item v-for="role in userRoles" :key="role">
                      <q-item-section>{{ role }}</q-item-section>
                      <q-item-section side>
                        <q-btn color="red" text-color="white" label="remove" @click="delUserRole(role)"/>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </div>
              </div>
          </q-form>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from '../axios'
import { useQuasar } from 'quasar'

export default {
  name: 'Home',
  components: {

  },
  data() {
    return {
        notify: useQuasar(),
        register: {},
        user: {},
        users: [],
        userRoles: [],
        roles: [],
        role: '',
        userDialog: false,
        filter: '',
        columns: [
        { name: 'id', align: 'center', label: 'Id', field: 'id', sortable: true },
        { name: 'email', align: 'center', label: 'Email', field: 'email', sortable: true },
        { name: 'positionTitle', align: 'center', label: 'Position', field: 'positionTitle', sortable: true },
        { name: 'officeLocation', align: 'center', label: 'Office', field: 'officeLocation', sortable: true },
      ]
    }
  },
  created() {
    this.getUsers()
    this.getRoles()
  },
  methods: {
    async getUsers() {
      axios
        .get('/api/Admin/Users')
        .then(res => {
          this.users = res.data
        })
    },
    async getUser(id) {
      await axios
        .get(`/api/Admin/Users/Id/${id}`)
        .then(res => {
          this.user = res.data
        })
    },
    async addUserRole() {
      await axios
        .post('/api/Admin/Users/AddToRole', {
          userId: this.user.id,
          roleId: this.role,
        })
      await this.getUserRoles(this.user.id)
    },
    async delUserRole(roleId) {
      await axios
        .delete(`/api/Admin/Users/${this.user.id}/Role/${roleId}`)
      await this.getUserRoles(this.user.id)
    },
    async getUserRoles(id) {
      await axios
        .get(`/api/Admin/Users/${id}/Roles`)
        .then(res => {
          this.userRoles = res.data
        })
    },
    async getRoles() {
      await axios
        .get('/api/Admin/Roles')
        .then(res => {
          this.roles = res.data
        })
    },
    async resetPassowrd() {
      await axios
        .post(`/api/Admin/Users/${this.user.id}/ChangePassword`, this.register)
        .then(res => {
          console.log(res)
          this.notify.notify({
            message: 'Login Successful',
            color: 'green'
          })
        })
    },
    async showDialog(id) {
      await this.getUser(id)
      await this.getUserRoles(id)
      this.userDialog = true
    }
  },
}
</script>

<style lang="scss">

</style>
